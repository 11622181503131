import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { FIREBASE_API } from '../config';
import { mergeDeep } from './merge';

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const STORAGE = getStorage(firebaseApp);

export { AUTH, DB, STORAGE };

export const uploadFileToStorage = async (file, filePath) => {
  const storageRef = ref(STORAGE, filePath);

  const result = await uploadBytes(storageRef, file);

  return result.metadata.fullPath;
};

export const getDownloadURLFromStorage = async (filePath) => {
  const storageRef = ref(STORAGE, filePath);

  const url = await getDownloadURL(storageRef);

  return url;
};

export const getMetaPeriods = async () => {
  const docRef = doc(DB, 'meta', 'periods');
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

export const getMetaVersions = async () => {
  const docRef = doc(DB, 'meta', 'versions');
  const docSnap = await getDoc(docRef);

  return docSnap.data();
};

export const listDocumentsPaginated = async (
  collectionName,
  pageSize = 50,
  startAfterDoc = null,
  search = null,
  needAction = false
) => {
  const collectionRef = collection(DB, collectionName);
  const q = query(
    collectionRef,
    orderBy('name'),
    limit(pageSize),
    ...(needAction ? [where('cm_status', '==', 'mismatch')] : []),
    ...(startAfterDoc ? [startAfter(startAfterDoc)] : [])
  );

  const querySnapshot = await getDocs(q);
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

  const queryAll = query(collectionRef, ...(needAction ? [where('cm_status', '==', 'mismatch')] : []));
  const queryAllSnapshot = await getDocs(queryAll);

  return {
    documents: querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
    lastVisible,
    count: queryAllSnapshot.size,
  };
};

export const listCompanies = (pageSize, startAfterDoc, needAction, search) =>
  listDocumentsPaginated('companies', pageSize, startAfterDoc, search, needAction);

export const getInvoicesByCompanyId = async (companyId) => {
  const invoicesRef = collection(DB, 'invoices');
  const q = query(invoicesRef, where('companyId', '==', companyId), orderBy('createdAt', 'asc'));
  const invoicesSnapshot = await getDocs(q);
  return invoicesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getMarksByCompanyId = async (companyId) => {
  const marksRef = collection(DB, 'marks');
  const q = query(marksRef, where('companyId', '==', companyId));
  const marksSnapshot = await getDocs(q);
  return marksSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getCompany = async (companyId) => {
  const companyDocRef = doc(DB, 'companies', companyId);
  const companyDocSnap = await getDoc(companyDocRef);
  const companyData = companyDocSnap.data();

  const chartMogulExternalId = companyData.tax || companyId;
  const companyChartmogulDocRef = doc(DB, 'chartmogul', chartMogulExternalId);
  const companyChartmogulDocSnap = await getDoc(companyChartmogulDocRef);
  const chartmogulData = companyChartmogulDocSnap.data();

  const invoices = await getInvoicesByCompanyId(companyId);

  const marks = await getMarksByCompanyId(companyId);
  const merged = invoices.map((invoice) => {
    const mark = marks.find((mark) => mark.id === invoice.id);
    return mergeDeep(invoice, mark);
  });

  return { id: companyId, ...companyData, invoices: merged, chartmogul: chartmogulData };
};

export const setDocument = async (collectionName, id, data) => {
  const docRef = doc(DB, collectionName, id);
  await setDoc(docRef, { ...data, updatedAt: new Date() }, { merge: true });
};
