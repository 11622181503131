import { Button, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import useAuth from 'hooks/useAuth';

export default function AuthFirebaseSocial() {
  const { login } = useAuth();

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={login}>
          <Iconify icon={'eva:google-fill'} color="#DF3E30" width={24} height={24} />
        </Button>
      </Stack>
      {/* <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider> */}
    </>
  );
}
