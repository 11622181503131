import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { AUTH, getCompany, getMetaPeriods, getMetaVersions, listCompanies, setDocument } from 'utils/firebase';

const ADMIN_EMAILS = ['caglar.yali@kolayik.com', 'ege.camlibel@kolayik.com', 'umit.dincel@kolayik.com'];

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // meta data
  const [periods, setPeriods] = useState([]);
  const [versions, setVersions] = useState([]);

  const fetchMeta = useCallback(async () => {
    const metaPeriods = await getMetaPeriods();
    setPeriods(metaPeriods?.values || []);

    const metaVersions = await getMetaVersions();
    setVersions(metaVersions?.values || []);

    // other meta data
  }, []);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user && ADMIN_EMAILS.includes(user.email)) {
          await fetchMeta();

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch, fetchMeta]
  );

  const login = () => signInWithPopup(AUTH, new GoogleAuthProvider());
  const logout = () => signOut(AUTH);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        meta: { periods, versions },
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          photoURL: state?.user?.photoURL,
          displayName: state?.user?.displayName,
        },
        login,
        logout,
        // firestore methods
        listCompanies,
        getCompany,
        updateMarkInvoice: (id, data) => setDocument('marks', id, data),
        markAsDone: (id) => setDocument('companies', id, { cm_status: 'synced' }),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
